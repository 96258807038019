<template>
  <div class="bk">
    <van-row justify="space-between" align="center" class="bkimg">
      <van-col span="12">
        <van-row justify="center" align="center" gutter="8">
          <van-col justify="center" align="center">
            <van-image
              :src="userinfo.userico"
              width="64"
              height="64"
              fit="cover"
              round
            />
            <div v-if="userinfo.userstate == '3'" style="font-size: 12px">
              未通过审核，请完善资料
            </div>
            <div
              v-if="userinfo.userstate == '0'"
              style="font-size: 12px; text-align: center"
            >
              审核中
            </div>
          </van-col>
          <van-col>
            <div>{{ userinfo.username }}</div>
            <!--<div style="font-size:12px;">会员ID:80316</div>-->
          </van-col>
        </van-row>
      </van-col>
      <van-col span="12">
        <!--<router-link to='/loginfo'>-->
        <van-row justify="center" align="center" @click="loginfo"
          >修改个人资料></van-row
        >
        <!--</router-link>-->
      </van-col>
    </van-row>

    <van-cell
      title="我的关注"
      :icon="require('../assets/fav1.png')"
      is-link
      to="/guanzhu"
    />
    <van-cell
      title="我的认证"
      :icon="require('../assets/sm.png')"
      is-link
      @click="morerz"
      value="未认证"
    />
    <!-- to="/xqmb"-->
    <van-cell
      title="我的相亲卡"
      :icon="require('../assets/xqk.png')"
      is-link
      @click="myxqk"
      value="点击生成相亲卡"
    />
    <van-cell
      title="我要置顶"
      :icon="require('../assets/zd.png')"
      is-link
      to="/buy"
      value="置顶增加相亲几率"
    />
    <van-cell
      title="是否显示我的资料"
      :icon="require('../assets/sjhm.png')"
      is-link
      value="显示中，点击关闭"
    />
    <van-cell
      title="谁看了我"
      :icon="require('../assets/jy.png')"
      is-link
      to="/lookme"
    />
    <van-cell
      title="相亲活动"
      :icon="require('../assets/hd.png')"
      is-link
      to="/party"
    />
    <van-cell
      title="联系客服"
      :icon="require('../assets/hn.png')"
      is-link
      @click="callPhone"
    />
      <van-cell
      title="关于平台"
      :icon="require('../assets/tip.png')"
      is-link
      @click="about"
    />
    <van-row justify="center">
      <van-button
        @click="logout"
        round
        color="#ec616a"
        style="width: 300px; margin: 50px auto; font-size: 15px"
        >退出登录</van-button
      >
    </van-row>

    <Footer />
  </div>
</template>

<script>
import { ref, getCurrentInstance, onMounted, reactive } from "vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Myinfo",
  components: {
    Footer,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const active = ref(0);
    var userinfo = reactive({
      userico: "",
      username: "",
      userstate: "",
    });
    const kftel=ref('');
    const callPhone = () => {
      window.location.href = "tel:"+kftel.value;//17776393183";
    };
    const getorderlist = () => {
      var myoption = {
        openid: "",
      };
      myoption.openid = localStorage.getItem("pnxqtel");
      //点击登录
      proxy.$http
        .get("https://gzlove.my306.com/gzlove/getmyinfo.php", {
          params: myoption,
        })
        .then(function (res) {
          // console.log(res.data.r2);
          //loading.value = false;
          userinfo.userico = res.data.r1.imglist.split("|")[0];
          userinfo.username = res.data.r1.name;
          userinfo.userstate = res.data.r1.state;
          localStorage.setItem("userstate", res.data.r1.state);
          localStorage.setItem("username", res.data.r1.name);
          /*if (res.data.r2 == "0") {
            finished.value = true;
          } else {
            //console.log("记录是" + res.data);
            mmlist.value = mmlist.value.concat(res.data.r1);
          }*/
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    onMounted(() => {
      kftel.value=localStorage.getItem('kftel');
      getorderlist();
      //userinfo.userico = localStorage.getItem("userico");
      //userinfo.username = localStorage.getItem("username");
    });
    const loginfo = () => {
      proxy.$router.push({
        path: "/loginfo",
        query: { type: "1" },
      });
    };
    const logout = () => {
      //localStorage.setItem('pnxqtel',"");//应该删除它
      localStorage.removeItem("pnxqtel");
      localStorage.removeItem("userico");
      localStorage.removeItem("username");
      localStorage.removeItem("userstate");
      localStorage.removeItem("userxb");
      localStorage.removeItem("showcarid");

      proxy.$router.push({
        path: "/index",
      });
    };
    const guanzhu = () => {
      proxy.$router.push({
        path: "/guanzhu",
      });
    };
    const morerz = () => {
      proxy.$router.push({
        path: "/renzhen",
        query: { openid: localStorage.getItem("pnxqtel"),title:'我' },
      });
    };
    const about= () => {
      proxy.$router.push({
        path: "/about",
      });
    };
    const myxqk= () => {
      var mytel=localStorage.getItem("pnxqtel") 
      localStorage.setItem('showcarid',mytel);
      proxy.$router.push({
        path: "/xqmb",
      });
    };
    return {
      about,
      active,
      loginfo,
      logout,
      userinfo,
      guanzhu,
      getorderlist,
      callPhone,
      morerz,
      myxqk
    };
  },
};
</script>


<style>
:root {
  --van-cell-font-size: 14px;
  --van-cell-line-height: 32px;
  --van-cell-icon-size: 20px;
}
</style>
<style scoped>
a {
  text-decoration: none;
  color: white;
}
.bk {
  font-size: 16px;
}
.bkimg {
  height: 120px;
  background-color: rgba(228, 100, 100, 0.986);
  color: white;
}
</style>
