<template>
  <div class="bk">
    <van-sticky>
      <van-row class="topbak">
        <van-col span="6" class="back" @click="back">
          <van-image
            width="24px"
            height="24px"
            :src="require('../assets/back.png')"
          />
        </van-col>
        <van-col span="12" style="text-align: center">
          <span class="nor1">谁看了我</span>
        </van-col>
        <van-col span="6"></van-col>
      </van-row>
    </van-sticky>

    <van-tabs v-model:active="active">
      <van-tab title="谁看了我" style="padding: 20px; height: 100vh">
        <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <van-row
            v-for="(item, index) in mmlist"
            :key="index"
            style="font-size: 10px; border-bottom: 1px solid #f7f7f7"
            justify="center"
            align="center"
            @click="clickme(index)"
          >
            <van-col span="6">
              <van-image
                fit="cover"
                width="68px"
                height="68px"
                :src="item.imglist"
            /></van-col>
            <van-col span="12">
              <div class="name">{{ item.name }}</div>
              <div class="f1">{{ item.age }}岁 {{ item.hy }} {{ item.sg }}</div>
              <div class="f1">{{ item.xg }} {{ item.xl }}</div>
            </van-col>
            <van-col span="6"
              ><van-button
                @click.stop="clicksc(index)"
                round
                size="small"
                style="width: 70px; background-color: #f7f7f7"
                >查看</van-button
              ></van-col
            >
          </van-row>
        </van-list>
      </van-tab>
      <!--<van-tab
        title="我的粉丝"
        style="padding: 20px; height: 100vh"
      >
        <van-list
          v-model:loading="loading2"
          :finished="finished2"
          finished-text="没有更多了"
          @load="onLoad2"
        >
          <van-row
            v-for="(item, index) in fslist"
            :key="index"
            style="font-size: 10px; border-bottom: 1px solid #f7f7f7"
            justify="center"
            align="center"
            @click="clickme2(index)"
          >
            <van-col span="6">
              <van-image
                fit="cover"
                width="68px"
                height="68px"
                :src="item.imglist"
            /></van-col>
            <van-col span="12">
              <div class="name">{{ item.name }}</div>
              <div class="f1">{{ item.age }}岁 {{ item.hy }} {{ item.sg }}</div>
              <div class="f1">{{ item.xg }} {{ item.xl }}</div>
            </van-col>
            <van-col span="6">
            </van-col>
          </van-row>
        </van-list>
      </van-tab>-->
    </van-tabs>
  </div>
</template>

<script>
import { ref, getCurrentInstance, onMounted } from "vue";
export default {
  name: "Guanzhu",
  setup() {
    const loading = ref(false);
    const finished = ref(false);

    const loading2 = ref(false);
    const finished2 = ref(false);

    const { proxy } = getCurrentInstance();
    const active = ref(0);
    const mmlist = ref([]);
    const fslist = ref([]);
    var myoption = {
      openid: "",
      pagesize: 12,
      pageindex: 0,
    };
    var myoption2 = {
      openid: "",
      pagesize: 12,
      pageindex: 0,
    };
    //var value1=ref("");   :immediate-check="false"
    //var value2=ref("");
    onMounted(() => {
      //getorderlist();
    });
    const getorderlist = () => {
      myoption.openid = localStorage.getItem("pnxqtel");
      //点击登录
      proxy.$http
        .get("https://gzlove.my306.com/gzlove/getlookinfo.php", {
          params: myoption,
        })
        .then(function (res) {
          console.log(res.data.r2);
          loading.value = false;
          if (res.data.r2 == "0") {
            finished.value = true;
          } else {
            //console.log("记录是" + res.data);
            mmlist.value = mmlist.value.concat(res.data.r1);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    const getorderlist2 = () => {
      myoption2.openid = localStorage.getItem("pnxqtel");
      //点击登录
      proxy.$http
        .get("https://gzlove.my306.com/gzlove/getfsinfo.php", {
          params: myoption2,
        })
        .then(function (res) {
          console.log(res.data.r2);
          loading2.value = false;
          if (res.data.r2 == "0") {
            finished2.value = true;
          } else {
            //console.log("记录是" + res.data);
            fslist.value = fslist.value.concat(res.data.r1);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    const back = () => {
      proxy.$router.go(-1);
    };

    const clicksc = (index) => {
      // console.log(mmlist)
      //var msg = "已取消关注";
      if (
        localStorage.getItem("pnxqtel") == null ||
        localStorage.getItem("pnxqtel") == ""
      ) {
        proxy.$router.push({ path: "/login" });
      } else {
        proxy.$router.push({
          path: "/userdetail",
          query: { userid: mmlist.value[index].id },
        });
      }
      /*
      let mybody = {
        myid: localStorage.getItem("pnxqtel"), //openid
        loveid: mmlist.value[index].openid,
        type: "0",
      };
      proxy.$http
        .post("https://gzlove.my306.com/gzlove/clicksc.php", mybody)
        .then(function (res) {
          console.log(res.data);
          mmlist.value.splice(index, 1);
          proxy.$toast("操作成功");
        })
        .catch(() => {});*/
    };
    const clickme = (index) => {
      //if (proxy.GLOBAL.openid == "") {
      if (
        localStorage.getItem("pnxqtel") == null ||
        localStorage.getItem("pnxqtel") == ""
      ) {
        proxy.$router.push({ path: "/login" });
      } else {
        proxy.$router.push({
          path: "/userdetail",
          query: { userid: mmlist.value[index].id },
        });
      }
    };
    const clickme2 = (index) => {
      //if (proxy.GLOBAL.openid == "") {
      if (
        localStorage.getItem("pnxqtel") == null ||
        localStorage.getItem("pnxqtel") == ""
      ) {
        proxy.$router.push({ path: "/login" });
      } else {
        proxy.$router.push({
          path: "/userdetail",
          query: { userid: fslist.value[index].id },
        });
      }
    };
    const onLoad = () => {
      myoption.pageindex = myoption.pageindex + 1; //触底了下一页
      getorderlist();
      console.log("触底了");
    };
    const onLoad2 = () => {
      myoption2.pageindex = myoption2.pageindex + 1; //触底了下一页
      getorderlist2();
      console.log("触底了2");
    };
    return {
      onLoad,
      loading,
      finished,
      onLoad2,
      loading2,
      finished2,
      clickme,
      clickme2,
      clicksc,
      active,
      getorderlist,
      mmlist,
      fslist,
      myoption,
      back,
      getorderlist2,
    };
  },
};
</script>
<style >
:root {
  --van-field-icon-size: 20px;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bk {
  height: 100vh;
  width: 100%;
  background-color: white;
  font-size: 14px;
}
.bk2 {
  padding: 20px;
}
.topbak {
  height: 50px;
  line-height: 50px;
  background-color: #ec616a;
  color: white;
}
.nor1 {
  font-weight: 450;
  margin-right: 10px;
  font-size: 18px;
}
.mytxt {
  font-size: 20px;
  margin: 30px 20px 30px 20px;
}
.back {
  padding-left: 10px;
  height: 50px;
  display: flex;
  align-items: center;
}
.name {
  font-size: 16px;
}
.f1 {
  font-size: 13px;
  color: #666666;
}
</style>
