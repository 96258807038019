<template>
  <div class="con1">
    关于本平台
</div>
  <div class="con2">
    广州同城佳缘帮助本地友仔友女们尽早脱单，最好难觅，合适就是最好。祝愿平台上的每个人能找到属于自己的爱情。
  </div>
  <div class="con3">
    <!-------- 广州同城佳缘 ------ -->
</div>
 <div class="cen">
          <van-image
            width="160px"
            height="160px"
            :src="require('../assets/code.jpg')"
          />
  </div>
  <div class="con3">
     <p>长按上方图片关注广州同城佳缘公众号，发现更多精彩。</p>
   客服电话（微信同号）：{{kftel}}
</div>


</template>

<script>
import { ref,onMounted } from 'vue';
export default {
  name: 'about',
  setup () {
    const active = ref(0);
    const kftel=ref('');
    onMounted(() => {
      kftel.value=localStorage.getItem('kftel');
    });
    return { active,kftel };
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.con1{
    padding: 100px 50px 0px 50px;
    box-sizing: border-box;
    font-size: 18px;
    text-align: center; 
    color:red;
    font-weight: 550;
}
.con2{
    padding: 30px 50px 0px 50px;
    box-sizing: border-box;
    font-size: 16px;
    text-align: center; 
}
.con3{
    padding: 10px 50px 0px 50px;
    box-sizing: border-box;
    font-size: 14px;
    text-align: center; 
}
.cen{
   text-align: center; 
}
</style>
