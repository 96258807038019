<template>
  <div class="bk">
    <van-sticky>
      <van-row class="topbak">
        <van-col span="6" class="back" @click="back">
          <van-image
            width="24px"
            height="24px"
            :src="require('../assets/back.png')"
          />
        </van-col>
        <van-col span="12" style="text-align: center">
          <span class="nor1">{{mytitle}}的认证</span></van-col
        >
        <van-col span="6"></van-col>
      </van-row>
    </van-sticky>

    <van-grid :border="false" :column-num="3">
      <van-grid-item>
        <van-image
          width="60px"
          height="60px"
          class="myimg2"
          :src="require('../assets/sj2.png')"
        />
        <div style="font-size: 13px; margin-top: 4px; color: #66666">
          手机认证
        </div>
      </van-grid-item>
      <van-grid-item @click="sfrz">
        <van-image
          v-if="sfstate == '0'"
          width="60px"
          height="60px"
          class="myimg"
          :src="require('../assets/sf.png')"
        />
         <van-image
          v-if="sfstate == '1'"
          width="60px"
          height="60px"
          class="myimg3"
          :src="require('../assets/sf2.png')"
        />
         <van-image
          v-if="sfstate == '2'"
          width="60px"
          height="60px"
          class="myimg2"
          :src="require('../assets/sf2.png')"
        />
          <van-image
          v-if="sfstate == '3'"
          width="60px"
          height="60px"
          class="myimg"
          :src="require('../assets/sf.png')"
        />
        <div style="font-size: 13px; margin-top: 4px; color: #66666">
          身份认证
        </div>
      </van-grid-item>
      <van-grid-item @click="wxrz(wxstate)">
        <van-image
          width="60px"
          height="60px"
          :class="wxstate == '0' ? 'myimg' : 'myimg2'"
          :src="
            wxstate == '0'
              ? require('../assets/wx.png')
              : require('../assets/wx2.png')
          "
        />
        <div style="font-size: 13px; margin-top: 4px; color: #66666">
          微信认证
        </div>
      </van-grid-item>

      <van-grid-item @click="wxrz(zrstate)">
        <van-image
          width="60px"
          height="60px"
          :class="zrstate == '0' ? 'myimg' : 'myimg2'"
          :src="
            zrstate == '0'
              ? require('../assets/zr.png')
              : require('../assets/zr2.png')
          "
        />
        <div style="font-size: 13px; margin-top: 4px; color: #66666">
          真人认证
        </div>
      </van-grid-item>

      <van-grid-item @click="wxrz(hystate)">
        <van-image
          width="60px"
          height="60px"
          :class="hystate == '0' ? 'myimg' : 'myimg2'"
          :src="
            hystate == '0'
              ? require('../assets/hk.png')
              : require('../assets/hk2.png')
          "
        />
        <div style="font-size: 13px; margin-top: 4px; color: #66666">
          婚况认证
        </div>
      </van-grid-item>

      <van-grid-item @click="wxrz(xlstate)">
        <van-image
          width="60px"
          height="60px"
          :class="xlstate == '0' ? 'myimg' : 'myimg2'"
          :src="
            xlstate == '0'
              ? require('../assets/xl.png')
              : require('../assets/xl2.png')
          "
        />
        <div style="font-size: 13px; margin-top: 4px; color: #66666">
          学历认证
        </div>
      </van-grid-item>

      <van-grid-item @click="wxrz(housestate)">
        <van-image
          width="60px"
          height="60px"
          :class="housestate == '0' ? 'myimg' : 'myimg2'"
          :src="
            housestate == '0'
              ? require('../assets/house.png')
              : require('../assets/house2.png')
          "
        />
        <div style="font-size: 13px; margin-top: 4px; color: #66666">
          住房认证
        </div>
      </van-grid-item>

      <van-grid-item @click="wxrz(ddstate)">
        <van-image
          width="60px"
          height="60px"
          :class="ddstate == '0' ? 'myimg' : 'myimg2'"
          :src="
            ddstate == '0'
              ? require('../assets/dd.png')
              : require('../assets/dd2.png')
          "
        />
        <div style="font-size: 13px; margin-top: 4px; color: #66666">
          到店认证
        </div>
      </van-grid-item>

    </van-grid>
  </div>
</template>

<script>
import { ref, getCurrentInstance, onMounted } from "vue";
export default {
  name: "Renzhen",
  setup() {
    var mytitle=ref("");
    var myopenid=ref("");
    var zrstate=ref("0");
    var hystate=ref("0");
    var xlstate=ref("0");
    var housestate=ref("0");
    var ddstate=ref("0");
    var sfstate = ref("0");
    var wxstate = ref("0");
    var rzlist = ref([]);
    const { proxy } = getCurrentInstance();
    const active = ref(false);
    const back = () => {
      proxy.$router.go(-1);
    };
    const sfrz = () => {
      if (sfstate.value == "0"||sfstate.value == "3") {
        proxy.$router.push({
          path: "/shiming",
        });
      }
    };
    const wxrz = (state) => {
      if (state != "2") {
        proxy.$router.push({
          path: "/wxrz",
        });
      }
    };
    onMounted(() => {
      myopenid.value = proxy.$route.query.openid;
      mytitle.value = proxy.$route.query.title;
      // console.log(id);
      // if (typeof id != "undefined") {
        // Notify("客户id是：" + proxy.GLOBAL.openid);
      // }

      getrz();
    });
    const getrz = () => {
      let mybody = {
        openid: myopenid.value   // localStorage.getItem("pnxqtel"),
      };

      proxy.$http
        .get("https://gzlove.my306.com/gzlove/getrzlist.php", {
          params: mybody,
        })
        .then(function (res) {
          rzlist.value = res.data.r1;
          // console.log(rzlist.value);
          for (var i = 0; i < rzlist.value.length; i++) {
            if (rzlist.value[i].type == "1")
              sfstate.value = rzlist.value[i].state;
            else if (rzlist.value[i].type == "2")
              wxstate.value = rzlist.value[i].state;
              else if (rzlist.value[i].type == "3")
              zrstate.value = rzlist.value[i].state;
              else if (rzlist.value[i].type == "4")
              hystate.value = rzlist.value[i].state;
              else if (rzlist.value[i].type == "5")
              xlstate.value = rzlist.value[i].state;
              else if (rzlist.value[i].type == "6")
              housestate.value = rzlist.value[i].state;
              else if (rzlist.value[i].type == "7")
              ddstate.value = rzlist.value[i].state;
          }
          console.log(sfstate.value);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    return {
      sfstate,
      wxstate,
      zrstate,
      hystate,
      xlstate,
      housestate,
      ddstate,
      rzlist,
      getrz,
      sfrz,
      back,
      active,
      wxrz,
      myopenid,
      mytitle
    };
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bk {
  width: 100%;
  height: 100%;
  background-color: white;
  position: fixed;
}
.topbak {
  height: 50px;
  line-height: 50px;
  background-color: #ec616a;
  color: white;
}
.nor1 {
  font-weight: 450;
  margin-right: 10px;
  font-size: 18px;
}

.back {
  padding-left: 10px;
  height: 50px;
  display: flex;
  align-items: center;
}
.myimg::before {
  content: "";
  background: url("../assets/cha.png") no-repeat top left / 24px 24px; /*兼容没测*/
  position: absolute;
  bottom: -2px;
  right: -10px;
  z-index: 2;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid white;
}
.myimg2::before {
  content: "";
  background: url("../assets/gou3.png") no-repeat top left / 24px 24px; /*兼容没测*/
  position: absolute;
  bottom: -2px;
  right: -10px;
  z-index: 2;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid white;
}
.myimg3::before {
  content: "";
  background: url("../assets/shz.png") no-repeat top left / 24px 24px; /*兼容没测*/
  position: absolute;
  bottom: -2px;
  right: -10px;
  z-index: 2;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid white;
}
</style>
