import { createRouter, createWebHashHistory} from 'vue-router'
import Index from '../components/Index.vue'
import Store from '../components/Store.vue'
import Yujian from '../components/Yujian.vue'
import Myinfo from '../components/Myinfo.vue'
import Party from '../components/Party.vue'
import Loginfo from '../components/Loginfo.vue'
import Errmsg from '../components/errmsg.vue'
import Userdetail from '../components/Userdetail.vue'
import Moreinfo from '../components/Moreinfo.vue'
import Login from '../components/Login.vue'
import Reg from '../components/Reg.vue'
import Buy from '../components/Buy.vue'
import Guanzhu from '../components/Guanzhu.vue'
import Search from '../components/Search.vue'
import Search2 from '../components/Search2.vue'
import Findpass from '../components/Findpass.vue'
import Shiming from '../components/Shiming.vue'
import Xqcard from '../components/Xqcard.vue'
import Lookme from '../components/Lookme.vue'
import Renzhen from '../components/Renzhen.vue'
import Artdetail from '../components/Artdetail.vue'
import Artlist from '../components/Artlist.vue'
import Wxrz from '../components/Wxrz.vue'
import Refreshme from '../components/Refreshme.vue'
import About from '../components/About.vue'
import Hddetail from '../components/Hddetail.vue'
import Xqmb from '../components/Xqmb.vue'
const routes = [
  {
    path: '/index',
    name: 'Index',
    component: Index,
    meta:{
      title:'广州同城佳缘——本地真实相亲平台',
      keepAlive: false // 需要缓存
    }
  },
  {
    path: '/xqmb',
    name: 'Xqmb',
    component: Xqmb,
    meta:{
      title:'选择相亲卡模板',
      keepAlive: false // 需要缓存
    }
  },
  {
    path: '/store',
    name: 'Store',
    component: Store,
    meta:{
      title:'广州同城佳缘',
      keepAlive: false // 需要缓存
    }
  },
  {
    path: '/yujian',
    name: 'Yujian',
    component: Yujian,
    meta:{
      title:'广州同城佳缘',
      keepAlive: true // 需要缓存
    }
  },
  {
    path: '/myinfo',
    name: 'Myinfo',
    component: Myinfo,
    meta:{
      title:'个人中心',
      keepAlive: false // 需要缓存
    }
  },
  {
    path: '/party',
    name: 'Party',
    component: Party,
    meta:{
      title:'相亲活动',
      keepAlive: false // 需要缓存
    }
  },
  {
    path: '/loginfo',
    name: 'Loginfo',
    component: Loginfo,
    meta:{
      title:'填写个人信息-广州同城佳缘',
      keepAlive: false // 需要缓存
    }
  },
  {
    path: '/errmsg',
    name: 'Errmsg',
    component: Errmsg,
    meta:{
      title:'广州同城佳缘',
      keepAlive: false // 需要缓存
    }
  },
  {
    path: '/userdetail',
    name: 'Userdetail',
    component: Userdetail,
    meta:{
      title:'个人信息',
      keepAlive: false // 需要缓存
    }
  },
  {
    path: '/moreinfo',
    name: 'Moreinfo',
    component: Moreinfo,
    meta:{
      title:'详细信息',
      keepAlive: false // 需要缓存
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta:{
      title:'登录',
      keepAlive: false // 需要缓存
    }
  },
  {
    path: '/reg',
    name: 'Reg',
    component: Reg,
    meta:{
      title:'开启相亲之旅-遇见你遇见爱',
      keepAlive: false // 需要缓存
    }
  },
  {
    path: '/buy',
    name: 'Buy',
    component: Buy,
    meta:{
      title:'购买特权',
      keepAlive: false // 需要缓存
    }
  },
  {
    path: '/guanzhu',
    name: 'Guanzhu',
    component: Guanzhu,
    meta:{
      title:'我的关注',
      keepAlive: false // 需要缓存
    }
  },
  {
    path: '/guanzhu',
    name: 'Guanzhu',
    component: Guanzhu,
    meta:{
      title:'我的关注',
      keepAlive: false // 需要缓存
    }
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
    meta:{
      title:'搜索',
      keepAlive: false // 需要缓存
    }
  },
  {
    path: '/search2',
    name: 'Search2',
    component: Search2,
    meta:{
      title:'搜索',
      keepAlive: false // 需要缓存
    }
  },
  {
    path: '/findpass',
    name: 'Findpass',
    component: Findpass,
    meta:{
      title:'忘记密码',
      keepAlive: false // 需要缓存
    }
  },
  {
    path: '/shiming',
    name: 'Shiming',
    component: Shiming,
    meta:{
      title:'实名认证',
      keepAlive: false // 需要缓存
    }
  },
  {
    path: '/xqcard',
    name: 'Xqcard',
    component: Xqcard,
    meta:{
      title:'我的相亲卡',
      keepAlive: false // 需要缓存
    }
  },
  {
    path: '/lookme',
    name: 'Lookme',
    component: Lookme,
    meta:{
      title:'我的相亲卡',
      keepAlive: false // 需要缓存
    }
  },
  {
    path: '/renzhen',
    name: 'Renzhen',
    component: Renzhen,
    meta:{
      title:'我的认证',
      keepAlive: false // 需要缓存
    }
  },
  {
    path: '/artdetail',
    name: 'Artdetail',
    component: Artdetail,
    meta:{
      title:'资讯详情',
      keepAlive: false // 需要缓存
    }
  },
  {
    path: '/artlist',
    name: 'Artlist',
    component: Artlist,
    meta:{
      title:'资讯列表',
      keepAlive: false // 需要缓存
    }
  },
  {
    path: '/wxrz',
    name: 'Wxrz',
    component: Wxrz,
    meta:{
      title:'微信认证',
      keepAlive: false // 需要缓存
    }
  },
  {
    path: '/refreshme',
    name: 'Refreshme',
    component: Refreshme,
    meta:{
      title:'刷新自身页',
      keepAlive: false // 需要缓存
    }
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta:{
      title:'关于平台',
      keepAlive: false // 需要缓存
    }
  },
  {
    path: '/hddetail',
    name: 'Hddetail',
    component:  Hddetail,
    meta:{
      title:'相亲活动详情',
      keepAlive: false // 需要缓存
    }
  }


 
  /*{
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/About.vue')
      //history: createWebHistory(process.env.BASE_URL),
  }*/
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
/*router.beforeEach((to,from,next)=>{
  if(to.path==='/') return next();
  next();
})*/

export default router
