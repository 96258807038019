<template>
  <div class="bk">
    <!--<van-sticky>
      <van-row class="topbak">
        <van-col span="6" class="back" @click="back">
          <van-image
            width="24px"
            height="24px"
            :src="require('../assets/back.png')"
          />
        </van-col>
        <van-col span="12" style="text-align: center">
          <span class="nor1">我的相亲卡</span></van-col
        >
        <van-col span="6"></van-col>
      </van-row>
    </van-sticky>-->
        <div class="totop" @click="toback">
      <div class="toleft"></div>
    </div>

    <div style="height: 50px; line-height:50px;text-align:center;background-color: #ec616a;color:white;">
     点击模板生成相亲卡
    </div>
    <van-grid
      :border="false"
      :column-num="2"
      :gutter="16"
      style="background-color: #ec616a"
      >
      <van-grid-item @click="clickme(index)" v-for="(item, index) in mmlist" :key="index" >
            <van-image
              radius="10"
              :src="item"
              height="230"
              width="100%"
              fit="cover"
              
            />
      </van-grid-item>
    </van-grid>


<div style="height: 100px;"></div>



    <!--<div ref="mypic">
      <van-image
        ref="barparent"
        width="100%"
        fit="contain"
        :src="require('../assets/mb2.jpg')"
      />
      <van-image
        crossorigin="anonymous"
        round
        :width="myw + 'px'"
        :height="myh + 'px'"
        fit="cover"
        :src="mmlist == null ? '' : mmlist.imglist"
        :style="
          'position: absolute;left:' + myleft + 'px;top: ' + mytop + 'px;'
        "
      />
      <div
        :style="
          'color:white;position: absolute;width:100%;left:0;text-align:center;top: ' +
          mytop2 +
          'px;'
        "
      >
        相亲卡号：{{ mmlist == null ? "" : mmlist.id }}
      </div>
      <div
        :style="
          'padding:50px;box-sizing:border-box;color:white;position: absolute;width:100%;left:0;text-align:center;top: ' +
          mytop3 +
          'px;'
        "
      >
        <span class="txt">{{ mmlist.xb == "0" ? "男" : "女" }}</span>
        <span class="txt">{{ mmlist == null ? "" : mmlist.age }}岁</span>
        <span class="txt">{{ mmlist == null ? "" : mmlist.xz }}</span>
        <span class="txt">属{{ mmlist == null ? "" : mmlist.sx }}</span>
        <span class="txt">{{ mmlist == null ? "" : mmlist.adress }}</span>
        <span class="txt">户籍{{ mmlist == null ? "" : mmlist.xg }}</span>
        <span class="txt">{{ mmlist == null ? "" : mmlist.hy }}</span>
        <span class="txt">身高{{ mmlist == null ? "" : mmlist.sg }}CM</span>
        <span class="txt">体重{{ mmlist == null ? "" : mmlist.tz }}KG</span>
        <span class="txt">{{ mmlist == null ? "" : mmlist.xl }}学历</span>
        <span class="txt">年收入{{ mmlist == null ? "" : mmlist.sr }}</span>
        <span class="txt">{{ mmlist == null ? "" : mmlist.house }}</span>
        <span class="txt">{{ mmlist == null ? "" : mmlist.car }}</span>
        <span class="txt">{{ mmlist == null ? "" : mmlist.zw }}</span>
        <span class="txt">{{
          mmlist.jieyi == "0" ? "介意离异" : "不介意离异"
        }}</span>
      </div>

      <div
        :style="
          'font-size:12px;color:white;position: absolute;width:100%;left:0;text-align:center;top: ' +
          mytop4 +
          'px;'
        "
      >
        广州同城佳缘网提供
      </div>
      <van-image
        :width="myw + 'px'"
        :height="myh + 'px'"
        fit="cover"
        :src="require('../assets/code.jpg')"
        :style="
          'position: absolute;left:' + myleft + 'px;top: ' + mytop5 + 'px;'
        "
      />
    </div>
    <div @click="xqk" class="scxqk">点击生成相亲卡</div>


    <van-overlay :show="show" @click="show = false" style="z-index: 99999">
      <div class="wrapper">
        <div class="block" @click.stop>
          <van-image width="300px" height="480px" fit="cover" :src="myxqk" />
        </div>
      </div>
    </van-overlay>-->
  </div>
</template>

<script>
import { ref, getCurrentInstance, onMounted } from "vue";
//import html2canvas from "html2canvas";
export default {
  name: "Xqmb",
  setup() {
    const { proxy } = getCurrentInstance();
    var mmlist = ref([
      require('../assets/mb0.jpg'),
      require('../assets/mb1.jpg'),
      require('../assets/mb2.jpg'),
      require('../assets/mb3.jpg'),
      require('../assets/mb4.jpg'),
      require('../assets/mb5.jpg'),
      require('../assets/mb6.jpg'),
      require('../assets/mb7.jpg'),
      require('../assets/mb8.jpg'),
      require('../assets/mb9.jpg')
    ]);
    var myleft = ref(0);
    var mytop = ref(0);
    var mytop2 = ref(0);
    var mytop3 = ref(0);
    var mytop4 = ref(0);
    var mytop5 = ref(0);
    var myw = ref(0);
    var myh = ref(0);
    var myxqk = ref(null);
    const show = ref(false);
    const vxcode=ref('');
    onMounted(() => {
      //vxcode.value=localStorage.getItem('vxcode');
      //getorderlist();
    });
    /*
    const getorderlist = () => {
      proxy.$http
        .get("https://gzlove.my306.com/gzlove/getmyinfo.php", {
          params: { openid: localStorage.getItem("pnxqtel") },
        })
        .then(function (res) {
          //console.log("来了");
          //console.log(res.data.r1);

          mmlist.value = res.data.r1;
          //mmlist.value["id"] = parseInt(mmlist.value["id"]) + 1000000;
          var imgstr = mmlist.value["imglist"];
          var imgarr = imgstr.split("|");
          mmlist.value["imglist"] = imgarr[0]; //第一张是封面

          const cWidth = proxy.$refs.barparent.$el.clientWidth;
          const cHeight = proxy.$refs.barparent.$el.clientHeight;
          myleft.value = (cWidth * 250) / 750 + 5;
          mytop.value = (cHeight * 25) / 1200 + 50 + 5;
          myw.value = (cWidth * 265) / 750 - 10;
          myh.value = (cHeight * 265) / 1200 - 10;

          mytop2.value = (cHeight * 300) / 1200 + 50;
          mytop3.value = (cHeight * 420) / 1200 + 50;
          mytop4.value = (cHeight * 1135) / 1200 + 50;
          mytop5.value = (cHeight * 850) / 1200 + 50;
          //console.log("高和宽是："+cWidth)
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    */
    const back = () => {
      proxy.$router.go(-1);
    };
    const clickme = (index) => {
      //if (proxy.GLOBAL.openid == "") {
      if (
        localStorage.getItem("showcarid") == null ||
        localStorage.getItem("showcarid") == ""
      ) {
        proxy.$router.push({ path: "/login" });
      } else {
        proxy.$router.push({
          path: "/xqcard",
          query: { id: index },
        });
      }
    };

    // 生成快照
    /*const convertToImage = (container, options = {}) => {
      // 设置放大倍数
      const scale = window.devicePixelRatio;

      // 传入节点原始宽高
      const _width = container.offsetWidth;
      const _height = container.offsetHeight;

      let { width, height } = options;
      width = width || _width;
      height = height || _height;

      // html2canvas配置项
      const ops = {
        scale,
        width,
        height,
        useCORS: true,
        allowTaint: false,
        ...options,
      };

      return html2canvas(container, ops).then((canvas) => {
        // 返回图片的二进制数据
        myxqk.value = canvas.toDataURL("image/png");
        show.value = true;
        //return canvas.toDataURL("image/png");
      });
    };
    const xqk = () => {
      //async () => {
      //const imgBlobData = convertToImage(proxy.$refs.mypic);
      //console.log(imgBlobData);
      proxy.$toast("长按图片保存到手机中，发给朋友");
      convertToImage(proxy.$refs.mypic);
      // };
      // 调用函数，取到截图的二进制数据，对图片进行处理（保存本地、展示等）
    };*/
    const toback = () => {
      proxy.$router.go(-1);
    };

    return {
      toback,
      show,
      //xqk,
      //convertToImage,
      mytop2,
      mytop3,
      mytop4,
      mytop5,
      myw,
      myh,
      myleft,
      mytop,
      mmlist,
      //getorderlist,
      back,
      myxqk,
      vxcode,
      clickme 
    };
  },
};
</script>
<style >
:root {
  --van-field-icon-size: 20px;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bk {
  width: 100%;
  height: 100%;
  background-color: #ec616a;
}
.topbak {
  height: 50px;
  line-height: 50px;
  background-color: #ec616a;
  color: white;
}
.nor1 {
  font-weight: 450;
  margin-right: 10px;
  font-size: 18px;
}
.mytxt {
  font-size: 20px;
  margin: 30px 20px 30px 20px;
}
.back {
  padding-left: 10px;
  height: 50px;
  display: flex;
  align-items: center;
}
.mask-image {
  width: 517px;
  height: 200px;

  /*background-color: #000;  */
  background-image: url("../assets/12196.png");
}
.txt {
  margin-right: 15px;
  line-height: 35px;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.scxqk {
  position: absolute;
  left: 0;
  bottom: 50px;
  height: 32px;
  line-height: 32px;
  width: 150px;
  color: white;
  font-size: 16px;
  background-color: rgba(92, 89, 85, 0.7);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  text-align: center;
}
.totop {
  position: fixed;
  top: 5px;
  left: 5px;
  z-index: 9998;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: rgba(92, 89, 85, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}
.toleft {
  margin-left: 4px;
  content: "";
  display: inline-block;
  height: 11px;
  width: 11px;
  border-width: 0 0 3px 3px;
  border-color: #fff;
  border-style: solid;
  transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
  -webkit-transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
}
</style>
