<template>
  <div class="bk">
    <van-sticky>
      <van-row class="topbak">
        <van-col span="6" class="back" @click="back">  
          <van-image
            width="24px"
            height="24px"
            :src="require('../assets/back.png')"/>
            </van-col>
        <van-col span="12" style="text-align: center">
          <span class="nor1">登录</span></van-col
        >
        <van-col span="6"></van-col>
      </van-row>
    </van-sticky>

    <div class="mytxt">会员登录</div>
    <van-cell-group inset>
      <van-field
        ref="user"
        center
        style="font-size: 16px"
        size="large"
        v-model="value1"
        :left-icon="require('../assets/user.png')"
        placeholder="请输入手机号或用户名"
      />
      <van-field
        ref="pass"
        center
        type="password"
        style="font-size: 16px; margin-top: 15px"
        v-model="value2"
        clearable
        size="large"
        :left-icon="require('../assets/pass.png')"
        placeholder="请输入登录密码"
      />
    </van-cell-group>
    <van-row justify="center">
      <van-button
        round
        color="#ec616a"
        style="width: 300px; margin: 50px 0px 30px 0px; font-size: 16px"
        @click="logme"
        >立即登录</van-button
      >
    </van-row>
    <van-row justify="center" style="font-size: 14px; margin-bottom: 15px">
     <div @click="findpass"> 忘记密码</div>
    </van-row>
    <van-row justify="center" style="font-size: 14px; padding: 0px 0px 20px 0x">
      <div @click="reg">没有帐号？点击注册</div>
    </van-row>
  </div>
</template>

<script>
import { ref, getCurrentInstance, reactive, toRefs } from "vue";
export default {
  name: "Login",
  setup() {
    const { proxy } = getCurrentInstance();
    const active = ref(0);
    var mybody = reactive({
      value1: "",
      value2: "",
    });
    //var value1=ref("");
    //var value2=ref("");
    const reg = () => {
      proxy.$router.push({ path: "/reg" });
    };
    const logme = () => {
      //点击登录
      if (mybody.value1 == "") proxy.$toast("请输入手机号或用户名");
      else if (mybody.value2 == "") proxy.$toast("请输入密码");
      else {
        proxy.$http
          .post("https://gzlove.my306.com/gzlove/login.php", mybody)
          .then(function (res) {
            console.log(res.data);
            if (res.data.r1 == "2") {
              proxy.$toast("手机号或用户名不存在。");
              //mybody.value1="";
              //mybody.value2="";
              proxy.$refs.user.focus();
            } else if (res.data.r1 == "0") {
              proxy.$toast("密码不正确，请重新输入。");
            } else {
              localStorage.setItem('pnxqtel',res.data.r2);//记录下返回的电话号码(不记录用户名，以电话号码为唯一)
              localStorage.setItem('userico',res.data.r3.imglist);
              localStorage.setItem('username',res.data.r3.name);
              localStorage.setItem('userstate',res.data.r3.state);//用户状态，是否已经审核通过等
              localStorage.setItem('userxb',res.data.r3.xb);// 性别 
              proxy.$router.push({ path: "/index" }); //密码正确
            }
          })
          .catch(() => {});
      }
    };
    const back=()=>{
       proxy.$router.go(-1);
    
    };
    const findpass=()=>{
      proxy.$router.push({ path: "/findpass" }); 
    };
    return {findpass, active, reg, logme, mybody, ...toRefs(mybody),back };
  },
};
</script>
<style >
:root {
  --van-field-icon-size: 20px;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bk {
  width: 100%;
  height: 100%;
  background-color: white;
  position: fixed;
}
.bk2 {
  padding: 20px;
}
.topbak {
  height: 50px;
  line-height: 50px;
  background-color: #ec616a;
  color: white;
}
.nor1 {
  font-weight: 450;
  margin-right: 10px;
  font-size: 18px;
}
.mytxt {
  font-size: 20px;
  margin: 30px 20px 30px 20px;
}
.back{
  padding-left: 10px;
  height: 50px;
  display: flex;
  align-items: center;
}
</style>
