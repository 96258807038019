<template>
  <div class="bk">
    <!--<van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item>
        <van-image
          :src="require('../assets/12196.png')"
          height="150"
          width="100%"
        />
      </van-swipe-item>
    </van-swipe>
    <van-notice-bar
      left-icon="volume-o"
      text="温馨提示：相关信息的真实性请进一步核实，相亲过程中，一定要提高保护意识，没有足够自我保护意识请别参与，涉及经济往来的切莫上当受骗。"
    />
    <van-sticky>
      <van-tabs v-model="active">
        <van-tab title="最新"></van-tab>
        <van-tab title="附近的人"></van-tab>
        <van-tab title="最匹配">内容 3</van-tab>
        <van-tab title="我要置顶">内容 4</van-tab>
        <van-tab title="搜索">内容 4</van-tab>
      </van-tabs>
    </van-sticky>-->
    <van-sticky>
      <van-row class="topbak">
        <van-col span="6"></van-col>
        <van-col span="12" style="text-align:center;"><span class="sel1">推荐</span> <span class="nor1">活动</span> <span class="nor1">搜索</span></van-col>
        <van-col span="6"></van-col>
      </van-row>
    </van-sticky>
    <div class="top3">
      <van-row class="topbak2">
        <van-col span="8" class="zhid">置顶推荐</van-col>
        <van-col span="16" class="shenq">也想展示在这里 立即申请置顶</van-col>
      </van-row>
      <van-row>
        <van-col span="4" class="xibao">
          <van-image
            class="myimg2"
            width="22"
            height="22"
            :src="require('../assets/love.png')"
          />喜报
        </van-col>
        <van-col span="20">
          <van-notice-bar
            color="RGB(102,102,102)"
            background="RGB(254,248,248)"
            :scrollable="false"
            style="height: 30px; border-radius: 15px"
          >
            <van-swipe
              vertical
              class="notice-swipe"
              :autoplay="3000"
              :show-indicators="false"
            >
              <van-swipe-item>恭喜NN20120男生喜提女友一枚！</van-swipe-item>
              <van-swipe-item>恭喜NN3234男生牵手成功！</van-swipe-item>
              <van-swipe-item>恭喜NN20120女生被评为心仪女生！</van-swipe-item>
            </van-swipe>
          </van-notice-bar>
        </van-col>
      </van-row>
    </div>
    <!--<div class="mybn">
      <div class="sel">推荐</div>
      <div class="nor">男生</div>
      <div class="nor">女生</div>
      <div class="nor">匹配</div>
    </div>-->
    <div class="mybn2">想尽快找到心仪的TA吗？我要立即展示</div>

    <van-grid :border="true" :column-num="2" :gutter="6">
      <van-grid-item v-for="(item, index) in mmlist" :key="index">
        <div @click="clickme(index)" style="width: 100%">
          <van-image
            :src="item.imglist"
            height="240"
            width="100%"
            fit="cover"
          />
          <div class="con1">
            <div>
              {{ item.name }}
              <van-image
                class="myimg"
                width="12"
                height="12"
                :src="require('../assets/girl.png')"
              />
            </div>
            <div class="agecolor">{{ item.age }}岁</div>
          </div>
          <div class="con2">
            <div>{{ item.xg }}</div>
            <div>
              <van-image
                class="myimg"
                width="20"
                height="20"
                :src="require('../assets/dh.png')"
              />
              <van-image
                class="myimg"
                width="20"
                height="20"
                :src="require('../assets/xc.png')"
              />
              <van-image
                class="myimg"
                width="20"
                height="20"
                :src="require('../assets/fz.png')"
              />
            </div>
          </div>
        </div>
        <div class="myrow">
          <van-row justify="center" align="center" gutter="30">
            <van-col span="12">
              <van-row justify="center" align="center" @click="clicksc(index)">
                <van-image
                  class="myimg"
                  width="24"
                  height="24"
                  :src="
                    item.blsc === '0'
                      ? require('../assets/sc.png')
                      : require('../assets/sc1.png')
                  "
                />收藏
              </van-row>
            </van-col>
            <van-col span="12">
              <van-row justify="center" align="center" @click="clickxq">
                <van-image
                  class="myimg"
                  width="24"
                  height="24"
                  :src="require('../assets/xin.png')"
                />相亲
              </van-row>
            </van-col>
          </van-row>
        </div>
      </van-grid-item>
    </van-grid>
    <div style="height: 90px"></div>
    <Footer />
  </div>
</template>

<script>
//import { Notify } from "vant";
import { ref, getCurrentInstance, onMounted } from "vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Store",
  components: {
    Footer,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    let mmlist = ref([]);
    onMounted(() => {
      getorderlist();
      /*let id = proxy.$route.query.userid;
      if (typeof id != "undefined") {
        proxy.GLOBAL.openid = id; //有传过来的，处理下
        Notify("客户id是：" + proxy.GLOBAL.openid);
      }*/
    });
    const getorderlist = () => {
      proxy.$http
        .get("https://gzlove.my306.com/gzlove/getscinfo.php", {
          params: { openid: proxy.GLOBAL.openid },
        })
        .then(function (res) {
          console.log("来了");
          mmlist.value = res.data.r1;
          console.log(res);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    const clicksc = (index) => {
      // console.log(mmlist)
      var msg = "已经收藏~~";
      if (mmlist.value[index].blsc === "0") {
        mmlist.value[index].blsc = "1";
      } else {
        msg = "已经取消收藏~~";
        mmlist.value[index].blsc = "0";
      }
      let mybody = {
        myid: proxy.GLOBAL.openid,
        loveid: mmlist.value[index].openid,
        type: mmlist.value[index].blsc,
      };
      proxy.$http
        .post("https://gzlove.my306.com/gzlove/clicksc.php", mybody)
        .then(function (response) {
          console.log(response.data);
        })
        .catch(() => {});
      proxy.$dialog
        .alert({
          title: "",
          message: msg,
          theme: "round-button",
        })
        .then(() => {
          // on close
        });
    };
    const clickxq = () => {
      console.log("来了");
      if (!proxy.GLOBAL.blreg) console.log("来了");
      else {
        proxy.$dialog
          .confirm({
            title: "温馨提示",
            message: "亲，查看Ta的资料请先完善您个人真实资料哦~",
          })
          .then(() => {
            // on confirm
            proxy.$router.push({ path: "/loginfo" });
          })
          .catch(() => {
            // on cancel
          });
      }
    };
    const clickme = (index) => {
      console.log("点击了" + index);
      //var tostr = "/userdetail?userid=" + mmlist.value[index].openid;
      //console.log(tostr)
      //proxy.$router.push({ path: '/news', query: { userId: 123 }});
      proxy.$router.push({
        path: "/userdetail",
        query: { userid: mmlist.value[index].openid },
      });
    };
    return {
      mmlist,
      getorderlist,
      clicksc,
      clickxq,
      clickme,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.myimg {
  display: inline-block;
}
/deep/ .van-grid-item__content {
  padding: 0px;
  border-radius: 10px;
}
.bk {
  color: rgb(102, 102, 102);
  background-color: #f6f6f6;
}
.bb {
  border: 1px solid #6666;
}
.con1 {
  font-size: 15px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 5px 0px 5px;
  box-sizing: border-box;
}
.con2 {
  font-size: 13px;
  border-bottom: 1px solid #6666;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 5px 3px 5px;
  box-sizing: border-box;
}
.myrow {
  padding: 3px 0px 8px 0px;
  box-sizing: border-box;
  font-size: 14px;
}
.agecolor {
  color: rgba(241, 119, 119, 0.993);
}
.topbak {
  height: 50px;
  line-height: 50px;
  background-color: rgba(237, 120, 116);
  color: white;
}
.topbak2 {
  padding: 8px 0px 8px 0px;
}
.zhid {
  color: rgba(237, 120, 116);
  font-size: 17px;
  font-weight: 510;
}
.shenq {
  font-size: 14px;
  text-align: right;
}
.notice-swipe {
  height: 40px;
  line-height: 40px;
}
.xibao {
  color: rgb(237, 119, 115);
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.top3 {
  padding: 10px 8px 10px 8px;
  box-sizing: border-box;
  margin: 10px 0px 10px 0px;
  background-color: white;
}
.mybn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px 5px 20px;
  background-color: white;
}
.mybn2 {
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-size: 14px;
  background-color: white;
  padding: 8px 0px 10px 0px;
}
.sel {
  background-color: rgb(237, 119, 115);
  color: white;
  font-weight: 500;
  height: 34px;
  line-height: 34px;
  text-align: center;
  width: 70px;
  border-radius: 17px;
  font-size: 16px;
}
.nor {
  color: rgb(102, 102, 102);
  font-weight: 480;
  height: 34px;
  line-height: 34px;
  text-align: center;
  width: 70px;
  border-radius: 17px;
  border: 1px solid rgb(242, 242, 242);
  font-size: 16px;
}
.sel1 {
  font-weight: 500;
  margin-right: 10px;
  font-size: 18px;
}
.nor1 {
  font-weight: 480;
  margin-right: 10px;
  font-size: 16px;
}
</style>
